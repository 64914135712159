import { Field, useForm, useFormState } from "react-final-form";
import { __ } from "@sportnet/ui/utilities";
import { rem } from "polished";
import Button from "@sportnet/ui/Button";
import Col, { Row } from "@sportnet/ui/Grid";
import FormField from "@sportnet/ui/FormField";
import Message from "@sportnet/ui/Message";
import Price from "@sportnet/ui/Price";
import Segment from "@sportnet/ui/Segment";
import SegmentHeader from "@sportnet/ui/Segment/Header";
import styled from "styled-components";

const MiddleAlignCol = styled(Col)`
  margin-top: ${rem(18)};
`;

const OrderFragments = () => {
  const { values } = useFormState();
  const orderfragments = values?.orderfragments || [];
  const { mutators } = useForm();

  return (
    <Col xs={12}>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__("Fragmenty objednávky")}
          </SegmentHeader>
        }
      >
        <Message primary>
          {__(
            "Fragmenty objednávky sú položky, ktoré navyšujú cenu v košíku a na objednávke sa prejavia ako samostatné položky. Môžete využiť napríklad na vratné obaly, kde sa účtuje iná DPH.",
          )}
        </Message>
        {orderfragments?.map((fragment, index) => {
          return (
            <Row>
              <Col xs={3}>
                <Field name={`orderfragments.${index}.code`}>
                  {({ input: { value, onChange } }) => (
                    <FormField
                      label={__("Kód") + ` #${index + 1}`}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={8}>
                <Field name={`orderfragments.${index}.name`}>
                  {({ input: { value, onChange } }) => (
                    <FormField
                      label={__("Názov") + ` #${index + 1}`}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                </Field>
              </Col>
              <MiddleAlignCol xs={1}>
                <Button
                  primary
                  onClick={() => {
                    const newOrderFragments = [
                      ...(orderfragments || []),
                    ].filter((i) => i.code !== fragment.code);
                    mutators.setOrderFragments(newOrderFragments);
                  }}
                  icon="trash"
                />
              </MiddleAlignCol>
              <Col xs={12}>
                <Field name={`orderfragments.${index}.unitprice`}>
                  {({ input: { onChange } }) => (
                    <Price
                      priceProp={`orderfragments.${index}.unitprice`}
                      onChange={(e) => {
                        onChange({
                          amount: e.amount,
                          amount_vat_excl: e.amountVatExcl,
                          vat_rate: e.vatRate,
                          currency: e.currency,
                        });
                      }}
                      amount={fragment.unitprice.amount}
                      amountVatExcl={fragment.unitprice.amount_vat_excl}
                      vatRate={fragment.unitprice.vat_rate}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          );
        })}
        <Button
          primary
          type="button"
          onClick={(e) => {
            const newOrderFragments = [...(orderfragments || [])];
            newOrderFragments.push({
              unitprice: {
                currency: "EUR",
                amount: 0,
                amount_vat_excl: 0,
                vat_rate: 0,
              },
            });
            mutators.setOrderFragments(newOrderFragments);
          }}
          icon="plus"
        />
      </Segment>
    </Col>
  );
};

export default OrderFragments;
