import { Field, Form } from "react-final-form";
import { ModalActions, ModalContent } from "@sportnet/ui/Modal";
import { __ } from "../../../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { preparedActiveParametersSelector } from "../../../Products/selectors";
import { required } from "../../../../utilities/validations";
import { useAuth } from "@sportnet/auth-react";
import Button from "@sportnet/ui/Button";
import FormField from "@sportnet/ui/FormField";
import React from "react";
import apiEshop from "../../../../service/apiEshop";
import withPopups from "../../../../components/WithPopups";

const SetDiscountOperation = ({
  productListParams,
  alert,
  handleClose,
  confirm,
  children,
}) => {
  const { ppo } = useAuth();

  const handleSubmit = async (data) => {
    if (
      !(await confirm(
        __(
          "Naozaj chcete upraviť DPH na všetky vybrané produkty a ich varianty?",
        ),
      ))
    ) {
      return;
    }
    try {
      await apiEshop.adminBulkProductOperation(ppo, {
        ...productListParams,
        data: {
          type: "SET_VAT_RATE",
          data: {
            vatRate: data.vatRate,
            keepAmount: data.keepAmount,
          },
        },
      });
      alert(__("Operácia prebehla úspešne!"), { success: true }).then(
        handleClose,
      );
    } catch (e) {
      const submissionError = {
        _error: __("Zmena sadzby DPH sa nepodarila."),
      };
      alert(submissionError._error);
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={{ vatRate: 23, keepAmount: "amount" }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              {children}
              <Field name="vatRate" validate={required}>
                {({ input: { value, onChange } }) => (
                  <FormField
                    type="number"
                    label="Nová sadzba DPH (%)"
                    info="Nová sadzba sa aplikuje na varianty produktov, ktoré nemajú nulovú sadzbu DPH."
                    onChange={onChange}
                    value={value}
                  />
                )}
              </Field>
              <Field name="keepAmount" validate={required}>
                {({ input: { value, onChange } }) => (
                  <>
                    <FormField
                      type="radio"
                      label="Ponechať cenu s DPH"
                      onChange={(e) => {
                        if (e.target.checked) {
                          onChange("amount");
                        }
                      }}
                      checked={value === "amount"}
                    />
                    <FormField
                      type="radio"
                      label="Ponechať cenu bez DPH"
                      onChange={(e) => {
                        if (e.target.checked) {
                          onChange("amountVatExcl");
                        }
                      }}
                      checked={value === "amountVatExcl"}
                    />
                  </>
                )}
              </Field>
            </ModalContent>
            <ModalActions>
              <Button onClick={handleClose}>{__("Zavrieť")}</Button>
              <Button primary loading={submitting} disabled={submitting}>
                {__("Vykonať operáciu")}
              </Button>
            </ModalActions>
          </form>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    productListParams: preparedActiveParametersSelector(state),
  };
};

export default compose(
  withPopups,
  connect(mapStateToProps),
)(SetDiscountOperation);
