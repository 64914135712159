import { __ } from "../../utilities";
import { connect } from "react-redux";
import Button from "@sportnet/ui/Button";
import DeletePricelevelOperation from "./Operations/DeletePricelevelOperation";
import FormField from "@sportnet/ui/FormField";
import Header from "@sportnet/ui/Header";
import Message from "@sportnet/ui/Message";
import Modal, { ModalActions, ModalContent } from "@sportnet/ui/Modal";
import PropTypes from "prop-types";
import React from "react";
import SetActivityPublicationOperation from "./Operations/SetActivityPublicationOperation";
import SetBrandOperation from "./Operations/SetBrandOperation";
import SetDiscountOperation from "./Operations/SetDiscountOperation";
import SetPricelevelOperation from "./Operations/SetPricelevelOperation";
import SetSaleschannelsOperation from "./Operations/SetSaleschannelsOperation";
import SetVatRateOperation from "./Operations/SetVatRateOperation";

const productBulkOperationsCodelist = [
  {
    value: "SET_PRICELEVEL",
    label: __("Nastavenie cien pre cenovú hladinu"),
  },
  {
    value: "DELETE_PRICELEVEL",
    label: __("Odstránenie cien pre cenovú hladinu"),
  },
  {
    value: "SET_DISCOUNT",
    label: __("Nastavenie zľavy"),
  },
  {
    value: "SET_SALESCHANNELS",
    label: __("Nastavenie predajných kanálov"),
  },
  {
    value: "SET_BRAND",
    label: __("Nastavenie značky"),
  },
  {
    value: "SET_PUBLICATION_ACTIVITY",
    label: __("Nastavenie aktívnosti"),
  },
  {
    value: "SET_VAT_RATE",
    label: __("Zmena sadzby DPH"),
  },
];

const ProductBulkOperations = ({
  isOpen,
  total,
  nextOffset = 0,
  handleClose,
}) => {
  const [operation, setOperation] = React.useState(null);

  const handleCloseModal = React.useCallback(() => {
    setOperation(null);
    handleClose();
  }, [handleClose]);

  const header = (
    <>
      <Header size="xs" withSeparator>
        {__("Hromadné operácie")}
      </Header>
      <Message primary>
        {__(
          "Hromadné operácie sú operácie, ktoré budú vykonané nad všetkými produktami, respektíve variantami všetkých produktov, ktoré vyhovujú filtrom zadaných v zozname.",
        )}
      </Message>
      <Message warning>
        {`${__("Počet ovplyvnených produktov")}: `}
        <b>{nextOffset ? "100+" : total}</b>
      </Message>
      <FormField
        label={__("Výber hromadnej operácie")}
        type="theselect"
        value={operation}
        onChange={setOperation}
        options={productBulkOperationsCodelist}
      />
    </>
  );

  let content = (
    <>
      <ModalContent>{header}</ModalContent>
      <ModalActions>
        <Button onClick={handleCloseModal}>{__("Zavrieť")}</Button>
      </ModalActions>
    </>
  );
  switch (operation && operation.value) {
    case "SET_PRICELEVEL":
      content = (
        <SetPricelevelOperation handleClose={handleCloseModal}>
          {header}
        </SetPricelevelOperation>
      );
      break;
    case "DELETE_PRICELEVEL":
      content = (
        <DeletePricelevelOperation handleClose={handleCloseModal}>
          {header}
        </DeletePricelevelOperation>
      );
      break;
    case "SET_DISCOUNT":
      content = (
        <SetDiscountOperation handleClose={handleCloseModal}>
          {header}
        </SetDiscountOperation>
      );
      break;
    case "SET_SALESCHANNELS":
      content = (
        <SetSaleschannelsOperation handleClose={handleCloseModal}>
          {header}
        </SetSaleschannelsOperation>
      );
      break;
    case "SET_BRAND":
      content = (
        <SetBrandOperation handleClose={handleCloseModal}>
          {header}
        </SetBrandOperation>
      );
      break;
    case "SET_PUBLICATION_ACTIVITY":
      content = (
        <SetActivityPublicationOperation handleClose={handleCloseModal}>
          {header}
        </SetActivityPublicationOperation>
      );
      break;
    case "SET_VAT_RATE":
      content = (
        <SetVatRateOperation handleClose={handleCloseModal}>
          {header}
        </SetVatRateOperation>
      );
      break;
    default:
      break;
  }

  return (
    <Modal isOpen={isOpen} handleClose={handleCloseModal} size="xs">
      {content}
    </Modal>
  );
};

ProductBulkOperations.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const {
    products: { data },
  } = state;

  return {
    total: data.total,
    nextOffset: data.nextOffset,
  };
};

export default connect(mapStateToProps)(ProductBulkOperations);
